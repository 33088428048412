import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import GlobalContext from "../../GlobalContext";
import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Chip,
  CircularProgress,
  TextField,
  MenuItem,
  Tooltip,
  Button,
  Icon,
  Box,
} from "@material-ui/core";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Loading from "../utils/Loading";
import { AccordianTable, AccordianRow, TableSort } from "../reusable/AccordianTable";
import { ExportToCsv } from "export-to-csv";
// import DriverSelect from '../reusable/DriverSelect';
// import RegionSelect from '../reusable/RegionSelect';
import PayPeriodDetailTable from "./driverPay2/PayPeriodDetailTable";
// import SendEmailButton from './driverPay2/SendEmailButton';
// import MarkAsPaidButton from './driverPay2/MarkAsPaidButton';

const log = false;

const cap = (str) => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
};

const checkNeg = (num) => {
  if (num > 0) return num;
  else return 0;
};

const round = (num, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  const output = Math.round(num * multiplier) / multiplier;
  return output;
};

const defaultOrder = `desc`;
const defaultOrderBy = `ID`;

const getPayPeriodId = (date) => {
  return `${date.weekYear()}-${String(date.week()).padStart(2, "0")}`;
};

moment.locale("en", { week: { dow: 0, doy: 4 } });

function DriverPay2(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [sunday, setSunday] = useState(moment().startOf("week").subtract(7, "days"));
  const [payPeriod, setPayPeriod] = useState(getPayPeriodId(moment()));

  const [status, setStatus] = useState(null);
  const localDriverId = parseInt(localStorage.getItem(`driverId`));
  const [driverId, setDriverId] = useState(localDriverId || null);
  const localRegionId = parseInt(localStorage.getItem(`regionId`));
  const [regionId, setRegionId] = useState(localRegionId || null);

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  useEffect(() => {
    localStorage.setItem(`driverId`, driverId);
  }, [driverId]);

  useEffect(() => {
    localStorage.setItem(`regionId`, regionId);
  }, [regionId]);

  useEffect(() => {
    setPayPeriod(getPayPeriodId(sunday.clone()));
  }, [sunday]);

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter((o) => {
        if (
          (o.driver_name && o.driver_name.toLocaleLowerCase().includes(search)) ||
          (o.driver_id && (o.driver_id + ``).toLocaleLowerCase().includes(search)) ||
          (o.total_pay_amount && (o.total_pay_amount + ``).toLocaleLowerCase().includes(search)) ||
          (o.status && (o.status + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = (pay_periods_by_driver) => {
    const createCsvRow = (pay_period) => {
      return {
        DRIVER_ID: pay_period.driver_id,
        DRIVER_NAME: pay_period.driver_name || `HopDriver`,
        REGION: pay_period.region.name,
        MOVES: pay_period.move_count,
        STATUS: pay_period.status ? cap(pay_period.status) : `-`,
        TOTAL: pay_period.total_pay_amount
          ? `$${pay_period.total_pay_amount.toFixed(2)}`
          : pay_period.total_pay_amount === 0
          ? `$0.00`
          : `-`,
      };
    };
    const csvRows = pay_periods_by_driver.map((pay_period) => createCsvRow(pay_period));
    const csvOptions = {
      filename: `${
        driverId ? pay_periods_by_driver[0].driver_name.replace(/ /g, "_") : `All`
      }_Driver_Pay_from_${sunday.format()}_to_${sunday.clone().endOf("week").format()}`,
      showTitle: true,
      title: `${
        driverId ? pay_periods_by_driver[0].driver_name : `All`
      } Driver Pay from ${sunday.format()} to ${sunday.clone().endOf("week").format()}`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const handleStatusChange = (event) => {
    if (event.target.value !== `any`) setStatus(event.target.value);
    else setStatus(null);
  };

  const handlePayPeriodChange = (event) => {
    if (event.target.value === "this-week") {
      setSunday(moment().startOf("week"));
    }
    if (event.target.value === "last-week") {
      setSunday(moment().startOf("week").subtract(1, "week"));
    }
    if (event.target.value === "next-week") {
      setSunday(moment().startOf("week").add(1, "week"));
    }
    if (event.target.value === "two-weeks-ago") {
      setSunday(moment().startOf("week").subtract(2, "weeks"));
    }
    if (event.target.value === "this-week-last-year") {
      setSunday(moment().startOf("week").subtract(52, "weeks"));
    }
    if (event.target.value === "two-weeks-out") {
      setSunday(moment().startOf("week").add(2, "weeks"));
    }
  };

  const getPayPeriodLabel = (date) => {
    if (date.format() === moment().startOf("week").format()) {
      return "this-week";
    }
    if (date.format() === moment().startOf("week").subtract(1, "week").format()) {
      return "last-week";
    }
    if (date.format() === moment().startOf("week").add(1, "week").format()) {
      return "next-week";
    }
    if (date.format() === moment().startOf("week").subtract(2, "weeks").format()) {
      return "two-weeks-ago";
    }
    if (date.format() === moment().startOf("week").subtract(52, "weeks").format()) {
      return "this-week-last-year";
    }
    if (date.format() === moment().startOf("week").add(2, "weeks").format()) {
      return "two-weeks-out";
    }
    return null;
  };

  const getDateRangeString = (start, end = null) => {
    if (end) {
      return (
        <Typography fontWeight={800} variant="body1">
          {start.format("dddd")} {start.format("MMM D")} through {end.format("dddd")} {end.format("MMM D")}{" "}
          <em>({getPayPeriodId(start)})</em>{" "}
        </Typography>
      );
    } else {
      return <em>&nbsp;{`(starting on ${start.format("dddd MMM D")})`}</em>;
    }
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={6}>
              <Typography className={cls.headTxt}>Driver Pay</Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography variant="h6" style={{ textAlign: "right" }} gutterBottom>
                {getDateRangeString(sunday.clone(), sunday.clone().endOf("week"))}
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Tooltip
                    title={`${sunday.clone().subtract(1, "week").format("MMM D")} - ${sunday
                      .clone()
                      .subtract(1, "week")
                      .endOf("week")
                      .format("MMM D")}`}
                  >
                    <Button
                      fullWidth
                      style={{ height: "100%" }}
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>keyboard_arrow_left</Icon>}
                      onClick={() => {
                        setSunday(sunday.clone().subtract(1, "week"));
                      }}
                    >
                      Prev
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title={`Select a predefined pay period`}>
                    <TextField
                      select
                      fullWidth
                      color="primary"
                      label="Pay Period"
                      placeholder="Select a pay period..."
                      variant="outlined"
                      margin="dense"
                      value={getPayPeriodLabel(sunday.clone()) || `custom`}
                      onChange={handlePayPeriodChange}
                      className={cls.input}
                    >
                      <MenuItem value={`this-week-last-year`}>
                        This Week Last Year {getDateRangeString(moment().startOf("week").subtract(52, "weeks"))}
                      </MenuItem>
                      <MenuItem value={`two-weeks-ago`}>
                        Two Weeks Ago {getDateRangeString(moment().startOf("week").subtract(2, "weeks"))}
                      </MenuItem>
                      <MenuItem value={`last-week`}>
                        Last Week {getDateRangeString(moment().startOf("week").subtract(1, "week"))}
                      </MenuItem>
                      <MenuItem value={`this-week`}>
                        This Week {getDateRangeString(moment().startOf("week").subtract(0, "weeks"))}
                      </MenuItem>
                      <MenuItem value={`next-week`}>
                        Next Week {getDateRangeString(moment().startOf("week").add(1, "weeks"))}
                      </MenuItem>
                      <MenuItem value={`two-weeks-out`}>
                        Two Weeks Out {getDateRangeString(moment().startOf("week").add(2, "weeks"))}
                      </MenuItem>
                      <MenuItem value={`custom`}>Custom</MenuItem>
                    </TextField>
                  </Tooltip>
                </Grid>
                <Grid item xs={3}>
                  <Tooltip
                    title={`${sunday.clone().add(1, "week").format("MMM D")} - ${sunday
                      .clone()
                      .add(1, "week")
                      .endOf("week")
                      .format("MMM D")}`}
                  >
                    <Button
                      fullWidth
                      style={{ height: "100%" }}
                      variant="outlined"
                      color="primary"
                      endIcon={<Icon>keyboard_arrow_right</Icon>}
                      onClick={() => {
                        setSunday(sunday.clone().add(1, "week"));
                      }}
                    >
                      Next
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <TextField
                select
                fullWidth
                label="Status Filter"
                placeholder="Select a status filter..."
                variant="outlined"
                margin="dense"
                value={status || `any`}
                className={cls.input}
                onChange={handleStatusChange}
              >
                <MenuItem value={`any`}>Any Status</MenuItem>
                <MenuItem value={`paid`}>Paid</MenuItem>
                <MenuItem value={`unpaid`}>Unpaid</MenuItem>
                <MenuItem value={`partial`}>Partial</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <div className={cls.break} />

          {ctx && ctx.userAuth && (
            <Query
              query={GET_PAY_PERIODS}
              variables={{ regionId: regionId, driverId: driverId, payPeriod: payPeriod, status: status }}
              onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve pay records.`)}
            >
              {({ loading, error, data, refetch }) => {
                if (loading) return <Loading fixed />;
                if (error) {
                  console.log(`Failed to retrieve pay_periods_by_driver:`, error);
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>ERROR FINDING AP RECORDS</Typography>
                    </div>
                  );
                }
                if (data && data.pay_periods_by_driver && data.pay_periods_by_driver.length > 0) {
                  const filteredPayPeriods = applyFilters(data.pay_periods_by_driver);

                  // Set a consistent amount object that holds the totals
                  var amount = {};

                  // Valid moves to calculate base totals
                  const subtotalPayments = filteredPayPeriods.filter((item) => item.total_pay_amount > 0);
                  const paidPayments = subtotalPayments.filter((item) => item.status === `paid`);

                  // Base totals from valid moves
                  amount.subtotal = round(
                    subtotalPayments.length > 0
                      ? subtotalPayments
                          .map((item) => item.total_pay_amount)
                          .reduce((total, current) => total + current)
                      : 0,
                    2
                  );
                  amount.paid = round(
                    paidPayments.length > 0
                      ? paidPayments.map((item) => item.total_pay_amount).reduce((total, current) => total + current)
                      : 0,
                    2
                  );

                  // Set calculations from base totals
                  amount.total = checkNeg(amount.subtotal);
                  amount.unpaid = checkNeg(amount.total - amount.paid);

                  const rows = filteredPayPeriods.map((payPeriod) => {
                    return {
                      EARNINGS_NUM: `${payPeriod.driver_id}-${payPeriod.pay_period}`,
                      DRIVER_NAME: payPeriod.driver_name,
                      REGION: (payPeriod.region && payPeriod.region.name) || "",
                      MOVES: payPeriod.move_count,
                      STATUS: (
                        <Chip
                          variant={payPeriod.status === "paid" ? "default" : "outlined"}
                          icon={
                            <Icon fontSize="small">{payPeriod.status === "paid" ? "done" : "monetization_on"}</Icon>
                          }
                          size="small"
                          color={payPeriod.status === "unpaid" ? "primary" : "secondary"}
                          label={payPeriod.status}
                        />
                      ),
                      TOTAL: payPeriod.total_pay_amount
                        ? `$${payPeriod.total_pay_amount.toFixed(2)}`
                        : payPeriod.total_pay_amount === 0
                        ? `$0.00`
                        : `-`,
                      payPeriod: payPeriod,
                    };
                  });

                  return (
                    <>
                      {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                        .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <PayPeriodDetailTable
                            key={`pay-period-table-${row.payPeriod.id}`}
                            payPeriod={row.payPeriod}
                          />
                        ))}
                    </>
                  );
                } else
                  return (
                    <div className={cls.notFound}>
                      <Typography className={cls.notFoundTxt}>NO DRIVER PAY FOUND</Typography>
                    </div>
                  );
              }}
            </Query>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  input: {
    margin: 0,
  },
  date: {
    width: "100%",
    margin: 0,
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      background: "#eee",
    },
    transition: "0.1s",
    cursor: "pointer",
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: "1px solid #ddd",
    borderRadius: "8px",
    marginLeft: "auto",
    marginRight: "auto",
    background: "#fff",
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: "21px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  break: {
    width: "100%",
    height: theme.spacing(2),
  },
}));

////////// GRAPHQL //////////
const GET_PAY_PERIODS = gql`
  query pay_periods_by_driver(
    $driverId: bigint
    $regionId: bigint
    $payPeriod: String
    $status: String
    $start: timestamp
    $end: timestamp
  ) {
    pay_periods_by_driver(
      where: {
        driver_id: { _eq: $driverId }
        pay_period: { _eq: $payPeriod }
        status: { _eq: $status }
        region_id: { _eq: $regionId }
        pickup_time_start: { _gte: $start, _lte: $end }
        pickup_time_end: { _lte: $end }
      }
      order_by: { driver_id: asc, pay_period: asc }
    ) {
      driver_id
      driver_name
      region {
        name
      }
      pay_period
      move_count
      total_pay_amount
      status
      pickup_time_start
      pickup_time_end
    }
  }
`;

////////// EXPORT //////////
export default withRouter(DriverPay2);
